<template>
  <div class="content-box">
    <div class="title">{{ data.title }}</div>
    <div class="meta">
      <div class="author">{{ data.author ? data.author : '管理员' }}</div>
      <div class="date">{{ data.create_time }}</div>
      <div class="cate-title">{{ data.cate_title }}</div>
    </div>
    <div class="cover">
      <img :src="data.full_cover" alt="" />
    </div>
    <div class="content">
      <div class="container--narrow">
        <div v-html="data.content"></div>
      </div>
    </div>
    <div class="share">
      <div class="share-title">分享</div>
      <div class="share-icon">
        <span
          ><img
            src="https://www.nio.cn/themes/nioweb/images/icon-news-down-v20211103.svg"
            alt=""
        /></span>
        <span
          ><img
            src="https://www.nio.cn/themes/nioweb/images/icon-weibo-v20211103.svg"
            alt=""
        /></span>
        <span
          ><img
            src="https://www.nio.cn/themes/nioweb/images/icon-wechat-v20211103.svg"
            alt=""
        /></span>
        <span
          ><img
            src="https://www.nio.cn/themes/nioweb/images/icon-chain-black-v20211103.svg"
            alt=""
        /></span>
      </div>
    </div>
  </div>
</template>

<script>
import article from "../api/article";
import state from "../store/state";
import router from "../router";
export default {
  name: "Article",
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    if (this.$route.query.id && this.$route.query.id > 0) {
      this.getArticle(this.$route.query.id);
    } else {
      router.push({
        path: "/",
      });
    }
  },
  methods: {
    getArticle(id) {
      const that = this;
      let website_id = state.websiteSet.website.id;

      article
        .getArticle(website_id, id)
        .then((res) => {
          console.log(res);
          if (res && res.code === 0) {
            that.data = res.data;
            document.title = that.data.title + ' - ' + state.websiteSet.website.title
          } else {
            console.log(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.content-box {
  max-width: 768px;
  margin: 50px auto;
}
.title {
  font-size: 32px;
}
.meta {
  margin: 20px auto;
  height: 40px;
  font-size: 14px;
}
.author {
  float: left;
  height: 40px;
  color: #54585a;
}
.date {
  float: left;
  margin-left: 20px;
}
.cate-title {
  float: right;
  border: 1px solid #000000;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}
.author,
.date,
.cate-title {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
}
.cover img {
  width: 100%;
}
.content {
  margin: 20px auto;
}
.content img {
  width: 100%;
  object-fit: cover;
}
.share {
  max-width: 100%;
  width: 100%;
  height: 30px;
  margin: 10px 0;
}
.share-title {
  font-weight: 600;
  width: 10%;
  display: inline-block;
  vertical-align: middle;
}
.share-icon {
  display: inline-block;
  vertical-align: middle;
}
.share-icon span {
  float: left;
  margin-left: 10px;
}
.share-icon img {
  width: 16px;
  height: 16px;
}
</style>
