<template>
  <div class="case-item">
      <Article></Article>
  </div>
</template>

<script>
import Article from "../../layouts/Article";
export default {
  name: "CaseItem",
  components:{
    Article
  }
};
</script>

<style scoped>

</style>